import '../styles/index.scss';
import 'bootstrap';
import logoSquare from '../images/logo-square.png';
import isURL from 'validator/es/lib/isURL';
import isHexColor from 'validator/es/lib/isHexColor';
import QrCode from 'qrcode';

console.log('webpack starterkit');

const desiredSize = 512;

const mainForm = document.getElementById('mainForm');
const result = document.getElementById('result');
const transparantCheck = document.getElementById('checkTransparant');
const downloadLink = document.getElementById('download');

function validateLink(e) {
  const input = e.target;
  const validityState = input.validity;

  if (validityState.valid || validityState.customError) {
    const valid = isURL(input.value, { url: true });
    if (!valid) input.setCustomValidity('Ongeldige URL');
    else {
      input.setCustomValidity('');
    }
  }
}

function validateColor(e) {
  const input = e.target;
  const validityState = input.validity;
  
  if (validityState.valid || validityState.customError) {
    const valid = isHexColor(input.value, { url: true });
    if (!valid) input.setCustomValidity("Ongeldige HEX kleur");
    else {
      input.setCustomValidity('');
    }
  }
}

const ctx = result.getContext('2d');

const placeholder = new Image(580, 580);
placeholder.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';
placeholder.onload = function() {
  ctx.drawImage(placeholder, 0, 0, 580, 580);
};

mainForm.addEventListener('submit', function(e) {
  e.preventDefault();

  const options = {
    errorCorrectionLevel: 'H',
    width: desiredSize,
    color: {
      dark: e.target.elements['color'].value,
      light: transparantCheck ? '#ffffff00' : '#ffffff'
    },
    margin: 0
  };

  const object = QrCode.create(e.target.elements['link'].value, options);

  console.log(object);

  const size = object.modules.size;
  const blockSize = Math.round(desiredSize / size);
  const length = blockSize * size;
  const desiredMargin = Math.ceil(size / 3);

  const x = desiredMargin * blockSize; 
  const y = desiredMargin * blockSize;
  const w = (size - desiredMargin * 2) * blockSize;
  const h = (size - desiredMargin * 2) * blockSize;

  result.width = length;
  result.height = length;
  
  options.width = length;

  QrCode.toCanvas(result, e.target.elements['link'].value, options, function(error) {
    if (error) {
      window.alert(error);
      return;
    }
    var image = new Image(w, h);
    image.src = logoSquare;

    image.onload = function() {
      ctx.clearRect(x, y, w, h);
      ctx.drawImage(image, x, y, w, h);
  
      result.src = result.toDataURL('png');
      downloadLink.href = result.src;
    };

    image.onerror = function(error) {
      window.alert(error);
    };
  });

  return false;
});

mainForm.elements['link'].addEventListener('input', validateLink);
mainForm.elements['color'].addEventListener('input', validateColor);
